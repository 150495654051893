import { render, staticRenderFns } from "./royalties.vue?vue&type=template&id=6c7f1c8d&scoped=true&"
import script from "./royalties.vue?vue&type=script&lang=js&"
export * from "./royalties.vue?vue&type=script&lang=js&"
import style0 from "./royalties.vue?vue&type=style&index=0&id=6c7f1c8d&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c7f1c8d",
  null
  
)

export default component.exports