<template>
  <div>
    <div class="setup">
      <div>
        <MenuThird :listName="'royalties'"></MenuThird>
      </div>
      <div>
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import MenuThird from "../../../components/menu/menu-third.vue";
export default {
  components: { MenuThird },

  directives: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
.setup {
  height: 100%;
  width: 100%;
}
</style>
